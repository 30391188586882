import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServiceAuth } from 'auth';
import { useTitle } from 'hooks/use-title';
import { getCore } from 'utils/aws-facade';
import Loading from 'components/Loading';
import Card from 'components/Card';
import CardGroup from 'components/CardGroup';
import CardButton from 'components/CardButton';
import { DEFAULT_BREADCRUMBS } from 'utils/constants';

const createChapterCards = (chapters) => {
  return chapters.map((chapter, index) =>
    <Card
      title={chapter.title}
      titlePath={`/chapters/${chapter.id}`}
      body={chapter.description}
      buttons={createLeadButtons(chapter.leads)}
      key={`chapter-card-${index}`}
      isBodySummarized={true}
    />
  );
};

const createLeadButtons = (leads) => {
  return leads?.map((lead, index) =>
    <CardButton
      label={lead.name}
      path={`/members/${lead.emplId}`}
      key={`card-button-${lead.emplId}-${index}`}
    />
  );
};

export default function Core({ setBreadcrumbs }) {
  const { getTokenSilently } = useServiceAuth();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [coreData, setCoreData] = useState({});
  useTitle(coreData.title ?? 'Loading');

  useEffect(() => {
    async function fetchData() {
      const response = await getCore(id, await getTokenSilently());
      setCoreData({ ...coreData, ...response.data });
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      ...DEFAULT_BREADCRUMBS,
      {
        text: coreData.title,
        path: `/cores/${coreData.id}`,
      }])
  }, [coreData, setBreadcrumbs]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{coreData.title}</h1>
            <CardGroup
              title="Chapters"
              description={coreData.description}
              cards={createChapterCards(coreData.chapters)}
            />
          </div>
        </div>
      </div>
    );
  }
}
