import React from 'react';

export default function CardGroup({ title, titlePath, description, cards }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          {titlePath
            ? <a href={titlePath}><h2><span className="highlight-gold">{title}</span></h2></a>
            : <h2><span className="highlight-gold">{title}</span></h2>
          }
        </div>
      </div>
      {description &&
        <div className="row">
          <div className="col-12 col-xl-6 col-lg-6">
            <p>
              {description}
            </p>
          </div>
        </div>
      }
      <div className="row pt-2">
        {cards}
      </div>
    </div>
  );
}
