import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useServiceAuth } from 'auth';
import Loading from 'components/Loading';

export default function ProtectedRoute({ children, ...args }) {
  const { isAuthenticated, isLoading, loginWithRedirect } = useServiceAuth();

  useEffect(() => {
    console.log('in auth required use effect');
    if (isLoading || isAuthenticated) {
      console.log('User is authenticated or auth is loading');
    } else {
      console.log('User is not authenticated');

      // TODO: use returnTo
      (async () => {
        await loginWithRedirect();
      })();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  return (
    <Route
      {...args}
      render={() => isAuthenticated ? children : <Loading />}
    />
  );
}
