import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

export default function SearchBar() {
  const [query, setQuery] = useState('');
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    history.push(`/search?query=${encodeURIComponent(query)}`);
  };

  return (
    <div className="row no-gutters white-bg border-0 py-3">
      <div className="container">
        <h1 className="page-title">
          <a href="https://tech.asu.edu">Enterprise Technology</a>
        </h1>
        <form
          className="uds-form"
          onSubmit={handleSubmit}
        >
          <div className="row my-4">
            <div className="col-4 mr-2">
              <label htmlFor="search-field">
                What or who are you looking for?
              </label>
              <input
                className="form-control"
                id="search-field"
                placeholder="Search by name or keyword"
                type="text"
                value={query}
                onChange={event => setQuery(event.target.value)}
              />
            </div>
            <div className="col pt-3">
              <button
                className="btn btn-maroon"
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
