import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';

export default function ContentSectionCard({
  contentSectionId,
  title,
  body,
  handleDelete,
  isDraggable,
  handleDragCard,
  handleDropCard,
}) {
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);

  return (
    <div className="row my-4">
      <div className="col col-xl-8 col-lg-8 col-md-8 col-sm-10 col-12 pb-2">
        <div
          className="card"
          id={contentSectionId}
          draggable={isDraggable}
          onDragOver={(event) => event.preventDefault()}
          onDragStart={handleDragCard}
          onDrop={handleDropCard}
        >
          <div className="card-header">
            <h3 className="card-header-text">{title}</h3>
            <span className="grip">
              <FontAwesomeIcon className="grip" icon={faGripVertical} />
            </span>
          </div>

          {body && (
            <div className="card-body">
              <p className="card-text">{body}</p>
            </div>
          )}

          <div className="card-tags">
            <a
              className="btn btn-tag"
              href={`/admin/content-sections/${contentSectionId}`}
            >
              Edit
            </a>
            <button
              className="btn btn-tag"
              onClick={() => setShowDeleteWarning(true)}
            >
              Delete
            </button>
          </div>

          {showDeleteWarning && (
            <div className="alert alert-danger m-2" role="alert">
              <div className="alert-icon pt-2">
                <FontAwesomeIcon icon={faTriangleExclamation} />
              </div>
              <div className="alert-content">
                Are you sure you want to{' '}
                <span className="font-weight-bold">permanently delete</span>{' '}
                content section {title}?
                <div className="row mt-3">
                  <button
                    className="btn btn-dark btn-sm"
                    onClick={() => setShowDeleteWarning(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-dark btn-sm ml-2"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
