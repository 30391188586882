import React from 'react';

export default function SkillsForm({ skills, setSkills }) {
  const handleSkillUpdate = (event, index) => {
    const skillData = [...skills];
    skillData[index] = event.target.value;
    setSkills(skillData);
  };

  const handleSkillAdd = (event) => {
    event.preventDefault();
    const skillData = [...skills];
    skillData.push('');
    setSkills(skillData);
  };

  const handleSkillDelete = (event, index) => {
    event.preventDefault();
    const skillData = [...skills];
    skillData.splice(index, 1);
    setSkills(skillData);
  };

  return (
    <div className="uds-table mb-4">
      <table>
        <thead>
          <tr>
            <th>Skill</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {skills?.map((skill, index) =>
            <tr key={`skill-row-${index}`}>
              <td>
                <div className="form-group">
                  <input
                    className="form-control"
                    value={skill}
                    onChange={event => handleSkillUpdate(event, index)}
                    name="skill"
                    placeholder="Skill..."
                  />
                </div>
              </td>
              <td style={{ paddingTop: '24px', verticalAlign: 'top' }}>
                <button
                  className="btn btn-sm btn-maroon"
                  onClick={event => handleSkillDelete(event, index)}
                >
                  Remove
                </button>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan="2">
              <button
                className="btn btn-md btn-maroon"
                onClick={event => handleSkillAdd(event)}
              >
                Add Skill
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
