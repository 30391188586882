import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useServiceAuth } from 'auth';
import { useTitle } from 'hooks/use-title';
import { search } from 'utils/aws-facade';
import SearchBar from 'components/SearchBar';
import Loading from 'components/Loading';
import CardGroup from 'components/CardGroup';
import Card from 'components/Card';

const createMemberCards = (members) => {
  return members.map((member, index) =>
    <Card
      title={`${member.firstName} ${member.lastName}`}
      titlePath={`/members/${member.emplId}`}
      body={member.title}
      key={`member-card-${index}`}
    />
  );
};

const createChapterCards = (chapters) => {
  return chapters.map((chapter, index) =>
    <Card
      title={chapter.title}
      titlePath={`/chapters/${chapter.id}`}
      key={`chapter-card-${index}`}
    />
  );
};

const createCoreCards = (cores) => {
  return cores.map((core, index) =>
    <Card
      title={core.title}
      titlePath={`/cores/${core.id}`}
      body={core.coreDescription}
      key={`org-core-card-${index}`}
    />
  );
};

export default function Search() {
  useTitle('ET Org Chart - Search');
  const [searchResults, setSearchResults] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [isResultsFound, setResultsFound] = useState(false);
  const location = useLocation();
  const { getTokenSilently } = useServiceAuth();

  useEffect(() => {
    const fetchData = async () => {
      const queryParams = new URLSearchParams(location.search);
      const query = queryParams.get('query');

      if (query) {
        setLoading(true);
        const response = await search(query, await getTokenSilently());

        setSearchResults(response.data);
        setResultsFound(response.data.members?.length > 0
          || response.data.chapters?.length > 0
          || response.data.cores?.length > 0);
      }
      setLoading(false);
    };

    fetchData();
  }, [location, getTokenSilently]);

  if (isLoading) {
    return (
      <>
        <SearchBar />
        <Loading />
      </>
    );
  } else {
    if (isResultsFound) {
      return (
        <>
          <SearchBar />

          <div className="container py-6">
            {searchResults.members?.length > 0 &&
              <div className="pb-6">
                <CardGroup
                  title="Members"
                  cards={createMemberCards(searchResults.members)}
                />
              </div>
            }

            {searchResults.chapters?.length > 0 &&
              <div className="pb-6">
                <CardGroup
                  title="Chapters"
                  cards={createChapterCards(searchResults.chapters)}
                />
              </div>
            }
            {searchResults.cores?.length > 0 &&
              <CardGroup
                title="Cores"
                cards={createCoreCards(searchResults.cores)}
              />
            }
          </div>
        </>
      );
    } else {
      return (
        <>
          <SearchBar />
          <div className="container py-6">
            <div className="row">
              <div className="col">
                <h2>No results found</h2>
              </div>
            </div>
          </div>
        </>
      )
    }
  }
}
