import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useServiceAuth } from 'auth';
import {
  getContentSection,
  createContentSection,
  updateContentSection,
  getAllContent,
  deleteContent,
} from 'utils/aws-facade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTriangleExclamation,
  faGripVertical,
} from '@fortawesome/free-solid-svg-icons';
import { useTitle } from 'hooks/use-title';
import Loading from 'components/Loading';

export default function ContentSectionForm() {
  useTitle('Add/Edit Content Section');
  const { getTokenSilently } = useServiceAuth();
  const { id } = useParams();
  const history = useHistory();
  const [title, setTitle] = useState();
  const [isTitleError, setTitleError] = useState(false);
  const [description, setDescription] = useState();
  const [content, setContent] = useState([]);
  const [orderNum, setOrderNum] = useState(0);
  const [isLoading, setLoading] = useState(true);
  const [draggedCardId, setDraggedCardId] = useState();

  const isTitleValid = () => {
    if (title) {
      setTitleError(false);
      return true;
    }
    setTitleError(true);
    return false;
  };

  const handleClickNewContent = async (event) => {
    event.preventDefault();
    if (isTitleValid()) {
      setLoading(true);
      const responseBody = await saveContentSection();

      if (id === 'new') {
        history.push(`/admin/content-sections/${responseBody.id}/content/new`);
      } else {
        history.push(`/admin/content-sections/${id}/content/new`);
      }
    }
  };

  const handleDragCard = (event) => setDraggedCardId(event.target.id);

  const handleDropCard = (event) => {
    const droppedOnCardId = event.currentTarget.id;
    const { orderNum: draggedCardOrder } = content.find(
      (contentItem) => contentItem.id === draggedCardId
    );
    const { orderNum: droppedOnCardOrder } = content.find(
      (contentItem) => contentItem.id === droppedOnCardId
    );

    const newContentOrder = content.map((contentItem) => {
      if (contentItem.id === draggedCardId) {
        contentItem.orderNum = droppedOnCardOrder;
      } else if (contentItem.id === droppedOnCardId) {
        contentItem.orderNum = draggedCardOrder;
      }

      return contentItem;
    });
    setContent(newContentOrder);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isTitleValid()) {
      setLoading(true);
      await saveContentSection();
      history.push('/admin/content-sections');
    }
  };

  const saveContentSection = async () => {
    const requestBody = {
      title,
      description,
      content,
      orderNum,
    };

    let response = {};
    if (id === 'new') {
      response = await createContentSection(
        requestBody,
        await getTokenSilently()
      );
    } else {
      response = await updateContentSection(
        id,
        requestBody,
        await getTokenSilently()
      );
    }

    return response.data;
  };

  const fetchData = async (id) => {
    const token = await getTokenSilently();
    const [contentSectionResponse, contentResponse] = await Promise.all([
      getContentSection(id, token),
      getAllContent(id, token),
    ]);

    let orderCounter = 0;
    const contentWithOrdering = contentResponse.data.map((contentItem) => ({
      ...contentItem,
      orderNum: contentItem.orderNum ?? orderCounter++,
    }));

    setTitle(contentSectionResponse.data.title);
    setDescription(contentSectionResponse.data.description);
    setOrderNum(contentSectionResponse.data.orderNum);
    setContent(contentWithOrdering);
    setLoading(false);
  };

  const removeContent = async (event, contentId) => {
    event.preventDefault();
    setLoading(true);
    await deleteContent(id, contentId, await getTokenSilently());
    fetchData(id);
  };

  const createContentCardTags = (contentId) => {
    return [
      <a
        className="btn btn-tag"
        href={`/admin/content-sections/${id}/content/${contentId}`}
        key={`edit-content-button-${contentId}`}
      >
        Edit
      </a>,
      <button
        className="btn btn-tag"
        onClick={(event) => removeContent(event, contentId)}
        key={`delete-content-button-${contentId}`}
      >
        Delete
      </button>,
    ];
  };

  useEffect(() => {
    if (id !== 'new') {
      fetchData(id);
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <h1>
              <span className="highlight-gold">Edit Content Section</span>
            </h1>
            <p className="my-4">
              Add, update, and remove content items (i.e. cores, chapters,
              scopes, portfolios, etc.) that appear within a content section.
            </p>

            <form className="uds-form">
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  aria-required="true"
                  aria-describedby="title-error-help"
                  id="title"
                  className={`form-control ${isTitleError ? 'is-invalid' : ''}`}
                  placeholder="Title..."
                  required
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                  onBlur={isTitleValid}
                />
                {isTitleError && (
                  <small
                    className="form-text invalid-feedback"
                    id="title-error-help"
                  >
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    Title is required
                  </small>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="description">Description</label>
                <textarea
                  id="description"
                  className="form-control"
                  placeholder="Description..."
                  rows="4"
                  value={description}
                  onChange={(event) => setDescription(event.target.value)}
                />
              </div>
            </form>

            <div className="row">
              <div className="col">
                <h3>Content</h3>
              </div>
            </div>

            <div className="row">
              {content
                .sort((a, b) => a.orderNum - b.orderNum)
                .map((content, index) => (
                  <div
                    className="col col-xl-6 col-lg-6 col-md-6 col-sm-12 pb-2"
                    key={`content-card-${index}`}
                  >
                    <div
                      className="card"
                      id={content.id}
                      draggable={true}
                      onDragOver={(event) => event.preventDefault()}
                      onDragStart={handleDragCard}
                      onDrop={handleDropCard}
                    >
                      <div className="card-header">
                        <h3 className="card-header-text">{content.title}</h3>
                        <span className="grip">
                          <FontAwesomeIcon
                            className="grip"
                            icon={faGripVertical}
                          />
                        </span>
                      </div>

                      {(content.description || content.leads) && (
                        <div className="card-body">
                          <p className="card-text">{content.description}</p>

                          <span className="font-weight-bold">Leads: </span>
                          <br />
                          {content.leads?.map((lead, leadIndex) => (
                            <div key={`lead-name-${leadIndex}`}>
                              {lead.name}
                            </div>
                          ))}
                        </div>
                      )}

                      <div className="card-tags">
                        {createContentCardTags(content.id)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>

            <div className="row mt-2 mb-6">
              <div className="col">
                <button
                  className="btn btn-maroon"
                  onClick={handleClickNewContent}
                >
                  Add New Content
                </button>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button className="btn btn-maroon mr-1" onClick={handleSubmit}>
                  Save
                </button>
                <button
                  className="btn btn-maroon"
                  onClick={() => history.push('/admin/content-sections')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
