import React, { useState, useEffect } from 'react';
import { useServiceAuth } from 'auth';
import { getAllAdminUsers, deleteAdminUser } from 'utils/aws-facade';
import Loading from 'components/Loading';

export default function UserTable() {
  const { getTokenSilently } = useServiceAuth();
  const [admins, setAdmins] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const fetchAdminData = async () => {
    const response = await getAllAdminUsers(await getTokenSilently());
    setAdmins(response.data);
    setLoading(false);
  };

  const removeAdmin = async (event, id) => {
    event.preventDefault();
    setLoading(true);
    await deleteAdminUser(id, await getTokenSilently());
    fetchAdminData();
  };

  useEffect(() => {
    fetchAdminData();
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <h1>
              <span className="highlight-gold">
                Manage Administrators
              </span>
            </h1>
            <p className="mt-4 mb-6">
              Add, update, and remove administrative user roles and permissions.  Administrative users can manage site content and other administrative user access.
            </p>

            <div className="uds-table">
              <table>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>ASURITE ID</th>
                    <th>Can Manage Users</th>
                    <th>Edit</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {admins.map((admin, index) =>
                    <tr key={`admin-row-${index}`}>
                      <td>
                        {admin.name}
                      </td>
                      <td>
                        {admin.id}
                      </td>
                      <td>
                        {admin.canManageUsers ? 'Yes' : 'No'}
                      </td>
                      <td>
                        <a
                          href={`/admin/users/${admin.id}`}
                          className="btn btn-sm btn-maroon"
                          aria-label={`Edit admin ${admin.name}`}
                          role="button"
                        >
                          Edit
                        </a>
                      </td>
                      <td>
                        <button
                          onClick={event => removeAdmin(event, admin.id)}
                          className="btn btn-sm btn-maroon"
                          aria-label={`Remove admin ${admin.name}`}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan="5">
                      <a
                        href="/admin/users/new"
                        className="btn btn-md btn-maroon"
                        role="button"
                      >
                        Add Admin User
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
