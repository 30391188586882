import API from './api';

const executeGet = async (path, token) => {
  return await API.get(path, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const executePost = async (path, body, token) => {
  return await API.post(path, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const executePatch = async (path, body, token) => {
  return await API.patch(path, body, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const executeDelete = async (path, token) => {
  return await API.delete(path, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

const getCore = async (id, token) => {
  console.log('Fetching cores data.');
  return await executeGet(`/cores/${id}`, token);
};

const getAllCores = async (token) => {
  console.log('Fetching all core data.');
  return await executeGet('/cores', token);
};

const getChapter = async (id, token) => {
  console.log('Fetching chapter specific data.');
  return await executeGet(`/chapters/${id}`, token);
};

const getMember = async (id, token) => {
  console.log('Fetching member specific data.');
  return await executeGet(`/members/${id}`, token);
};

const getAllPortfolios = async (token) => {
  console.log('Fetching all portfolio data.');
  return await executeGet('/portfolios', token);
};

const getAllScopes = async (token) => {
  console.log('Fetching all scope data.');
  return await executeGet('/scopes', token);
};

const getAllCoaches = async (token) => {
  console.log('Fetching all coach data.');
  return await executeGet('/coaches', token);
};

const search = async (query, token) => {
  console.log('Fetching search data.');
  return await executeGet(`/search/${query}`, token);
};

const getAllAdminUsers = async (token) => {
  console.log('Fetching all admin users.');
  return await executeGet('/users', token);
};

const getAdminUser = async (id, token) => {
  console.log(`Fetching admin user ${id}`);
  return await executeGet(`/users/${id}`, token);
};

const createAdminUser = async (body, token) => {
  console.log('Creating new user');
  return await executePost('/users', body, token);
};

const updateAdminUser = async (id, body, token) => {
  console.log(`Updating admin user ${id}`);
  return await executePatch(`/users/${id}`, body, token);
};

const deleteAdminUser = async (id, token) => {
  console.log(`Deleting admin user ${id}`);
  return await executeDelete(`/users/${id}`, token);
};

const getAllContentSections = async (token) => {
  console.log('Fetching all content sections.');
  return await executeGet('/content-sections', token);
};

const getContentSection = async (id, token) => {
  console.log(`Fetching content section ${id}.`);
  return await executeGet(`/content-sections/${id}`, token);
};

const createContentSection = async (body, token) => {
  console.log('Creating new content section.');
  return await executePost('/content-sections', body, token);
};

const updateContentSection = async (id, body, token) => {
  console.log(`Updating content section ${id}.`);
  return await executePatch(`/content-sections/${id}`, body, token);
};

const deleteContentSection = async (id, token) => {
  console.log(`Deleting content section ${id}.`);
  return await executeDelete(`/content-sections/${id}`, token);
};

const getAllContent = async (id, token) => {
  console.log(`Fetching all content for content section ${id}.`);
  return await executeGet(`/content-sections/${id}/content`, token);
};

const getContent = async (contentSectionId, contentId, token) => {
  console.log(
    `Fetching content ${contentId} for content section ${contentSectionId}.`
  );
  return await executeGet(
    `/content-sections/${contentSectionId}/content/${contentId}`,
    token
  );
};

const createContent = async (contentSectionId, body, token) => {
  console.log('Creating new content.');
  return await executePost(
    `/content-sections/${contentSectionId}/content`,
    body,
    token
  );
};

const updateContent = async (contentSectionId, contentId, body, token) => {
  console.log(
    `Updating content ${contentId} for content section ${contentSectionId}.`
  );
  return await executePatch(
    `/content-sections/${contentSectionId}/content/${contentId}`,
    body,
    token
  );
};

const deleteContent = async (contentSectionId, contentId, token) => {
  console.log(
    `Deleting content ${contentId} from content section ${contentSectionId}.`
  );
  return await executeDelete(
    `/content-sections/${contentSectionId}/content/${contentId}`,
    token
  );
};

const getAllDepartmentDetails = async (token) => {
  console.log('Fetching all department details.');
  return await executeGet('/department-details', token);
};

const getDepartmentDetails = async (id, token) => {
  console.log(`Fetching department details for ${id}.`);
  return await executeGet(`/department-details/${id}`, token);
};

const createDepartmentDetails = async (body, token) => {
  console.log('Creating new department details.');
  return await executePost('/department-details', body, token);
};

const updateDepartmentDetails = async (id, body, token) => {
  console.log(`Updating department detail ${id}.`);
  return await executePatch(`/department-details/${id}`, body, token);
};

const deleteDepartmentDetails = async (id, token) => {
  console.log(`Deleting department details ${id}.`);
  return await executeDelete(`/department-details/${id}`, token);
};

export {
  getCore,
  getAllCores,
  getChapter,
  getMember,
  getAllPortfolios,
  getAllScopes,
  getAllCoaches,
  search,
  getAllAdminUsers,
  getAdminUser,
  createAdminUser,
  updateAdminUser,
  deleteAdminUser,
  getAllContentSections,
  getContentSection,
  createContentSection,
  updateContentSection,
  deleteContentSection,
  getAllContent,
  getContent,
  createContent,
  updateContent,
  deleteContent,
  getAllDepartmentDetails,
  getDepartmentDetails,
  createDepartmentDetails,
  updateDepartmentDetails,
  deleteDepartmentDetails,
};
