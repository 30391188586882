import React, { useState, useEffect } from 'react';
import { useTitle } from 'hooks/use-title';
import { useServiceAuth } from 'auth';
import {
  getAllContentSections,
  updateContentSection,
  deleteContentSection,
} from 'utils/aws-facade';
import ContentSectionCard from 'components/ContentSectionCard';
import Loading from 'components/Loading';

export default function ContentSectionTable() {
  useTitle('Manage Content Sections');
  const { getTokenSilently } = useServiceAuth();
  const [contentSections, setContentSections] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [draggedCardId, setDraggedCardId] = useState();

  const handleDelete = async (event, contentSectionId) => {
    event.preventDefault();
    setLoading(true);
    await deleteContentSection(contentSectionId, await getTokenSilently());
    fetchData();
  };

  const updateContentSectionOrder = async (contentSection) => {
    const requestBody = {
      id: contentSection.id,
      title: contentSection.title,
      description: contentSection.description,
      orderNum: contentSection.orderNum,
    };
    updateContentSection(
      contentSection.id,
      requestBody,
      await getTokenSilently()
    );
  };

  const handleDragCard = (event) => setDraggedCardId(event.target.id);

  const handleDropCard = (event) => {
    const droppedOnCardId = event.currentTarget.id;
    const { orderNum: draggedCardOrder } = contentSections.find(
      (contentSection) => contentSection.id === draggedCardId
    );
    const { orderNum: droppedOnCardOrder } = contentSections.find(
      (contentSection) => contentSection.id === droppedOnCardId
    );

    const newContentSectionOrder = contentSections.map((contentSection) => {
      let isUpdated = false;
      if (contentSection.id === draggedCardId) {
        contentSection.orderNum = droppedOnCardOrder;
        isUpdated = true;
      } else if (contentSection.id === droppedOnCardId) {
        contentSection.orderNum = draggedCardOrder;
        isUpdated = true;
      }

      if (isUpdated) {
        console.log(
          `Content section titled ${contentSection.title} was updated to orderNum ${contentSection.orderNum}`
        );
        updateContentSectionOrder(contentSection);
      }

      return contentSection;
    });
    setContentSections(newContentSectionOrder);
  };

  const fetchData = async () => {
    const response = await getAllContentSections(await getTokenSilently());

    let orderCounter = 0;
    const contentSectionsWithOrdering = response.data.map((contentSection) => ({
      ...contentSection,
      orderNum: contentSection.orderNum ?? orderCounter++,
    }));

    setContentSections(contentSectionsWithOrdering);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col-12">
            <h1>
              <span className="highlight-gold">Manage Content Sections</span>
            </h1>
            <p className="my-4">
              Content sections appear as a collections of items (i.e. cores,
              chapters, scopes, portfolios, etc.) under a section header and
              description. Content sections will be displayed to users in the
              order specified on this management page.
            </p>

            <a
              className="btn btn-maroon"
              role="button"
              href="/admin/content-sections/new"
            >
              Add Section
            </a>

            {contentSections
              .sort((a, b) => a.orderNum - b.orderNum)
              .map((contentSection, index) => (
                <ContentSectionCard
                  contentSectionId={contentSection.id}
                  title={contentSection.title}
                  body={contentSection.description}
                  handleDelete={(event) =>
                    handleDelete(event, contentSection.id)
                  }
                  isDraggable={true}
                  handleDragCard={handleDragCard}
                  handleDropCard={handleDropCard}
                  key={`content-section-card-${index}`}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}
