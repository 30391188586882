import React, { useEffect, useState } from 'react';
import { useTitle } from 'hooks/use-title';
import { useServiceAuth } from 'auth';
import {
  getAllDepartmentDetails,
  deleteDepartmentDetails,
} from 'utils/aws-facade';
import Loading from 'components/Loading';

export default function DepartmentDetailsTable() {
  useTitle('Manage Department Details');
  const { getTokenSilently } = useServiceAuth();
  const [departmentDetails, setDepartmentDetails] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const fetchData = async () => {
    const response = await getAllDepartmentDetails(await getTokenSilently());
    console.log(`response: ${JSON.stringify(response.data)}`);
    setDepartmentDetails(response.data);
    setLoading(false);
  };

  const removeDepartmentDetails = async (event, id) => {
    event.preventDefault();
    setLoading(true);
    await deleteDepartmentDetails(id, await getTokenSilently());
    fetchData();
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <h1>
              <span className="highlight-gold">Manage Department Details</span>
            </h1>
            <p className="mt-4 mb-6">
              Add, update, and remove details that appear in the ET Org Chart
              for core and chapter entities.
            </p>

            <div className="uds-table">
              <table>
                <thead>
                  <tr>
                    <th>Title</th>
                    <th>ID</th>
                    <th>Description</th>
                    <th>Leads</th>
                    <th>Skills</th>
                    <th>Edit</th>
                    <th>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="7">
                      <a
                        href="/admin/department-details/new"
                        className="btn btn-md btn-maroon"
                        role="button"
                      >
                        Add Department Details
                      </a>
                    </td>
                  </tr>
                  {departmentDetails.map((departmentDetail, index) => (
                    <tr key={`detail-row-${index}`}>
                      <td>{departmentDetail.title}</td>
                      <td>{departmentDetail.id}</td>
                      <td>{departmentDetail.description}</td>
                      <td>
                        <ul>
                          {departmentDetail.leads?.map((lead) => (
                            <li
                              key={`${departmentDetail.id}-lead-${lead.asuriteId}`}
                            >
                              {lead.name}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <ul>
                          {departmentDetail.skills?.map((skill, index) => (
                            <li key={`${departmentDetail.id}-skill-${index}`}>
                              {skill}
                            </li>
                          ))}
                        </ul>
                      </td>
                      <td>
                        <a
                          href={`/admin/department-details/${departmentDetail.id}`}
                          className="btn btn-sm btn-maroon"
                          aria-label={`Edit department details for ${departmentDetail.title}`}
                          role="button"
                        >
                          Edit
                        </a>
                      </td>
                      <td>
                        <button
                          onClick={(event) =>
                            removeDepartmentDetails(event, departmentDetail.id)
                          }
                          className="btn btn-sm btn-maroon"
                          aria-label={`Remove department details for ${departmentDetail.title}`}
                        >
                          Remove
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
