import React from 'react';
import Breadcrumb from './Breadcrumb';

export default function Breadcrumbs({ breadcrumbs }) {
  return breadcrumbs.length <= 1
    ? <></>
    : (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <nav aria-label="breadcrumbs">
              <ol className="breadcrumb">
                {breadcrumbs.length > 1 &&
                  breadcrumbs.map((breadcrumb, index) => <Breadcrumb breadcrumb={breadcrumb} isCurrent={index === breadcrumbs.length - 1} key={`breadcrumb-${index}`} />)
                }
              </ol>
            </nav>
          </div>
        </div>
      </div>
    );
}
