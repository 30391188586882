import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServiceAuth } from 'auth';
import { useTitle } from 'hooks/use-title';
import { getMember } from 'utils/aws-facade';
import Loading from 'components/Loading';
import CardGroup from 'components/CardGroup';
import Card from 'components/Card';
import { DEFAULT_BREADCRUMBS } from 'utils/constants';

const createChapterCard = (chapter) => {
  return (
    <Card
      title={chapter.title}
      titlePath={`/chapters/${chapter.id}`}
    />
  );
};

export default function Member({ setBreadcrumbs }) {
  const { getTokenSilently } = useServiceAuth();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [member, setMember] = useState({});
  useTitle(member.firstName && member.lastName ? `${member.firstName} ${member.lastName}` : 'Loading');

  useEffect(() => {
    async function fetchData() {
      const response = await getMember(id, await getTokenSilently());
      setMember({ ...member, ...response.data });
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      ...DEFAULT_BREADCRUMBS,
      {
        text: member?.chapter?.core?.title,
        path: `/cores/${member?.chapter?.core?.id}`,
      },
      {
        text: member?.chapter?.title,
        path: `/chapters/${member?.chapter?.id}`,
      },
      {
        text: `${member.firstName} ${member.lastName}`,
        path: `/members/${member.emplId}`,
      },
    ])
  }, [member, setBreadcrumbs]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container mt-5">
        <div className="row mb-10">
          <div className="col-12">
            <div className="uds-person-profile">
              <div className="profile-img-container">
                <div className="profile-img-placeholder">
                  <img
                    className="profile-photo"
                    alt={`${member.firstName} ${member.lastName}`}
                    src={`https://webapp4.asu.edu/photo-ws/directory_photo/${member.asuriteId}?blankImage=true&size=medium`}
                  />
                </div>
              </div>
              <div className="person">
                <h1 className="person-name member-name">
                  {member.firstName} {member.lastName}
                </h1>
                <div className="person-profession">
                  <h2 className="member-title">
                    <span>
                      {member.title}
                    </span>
                  </h2>
                </div>
                <ul className="person-contact-info">
                  {member.email &&
                    <li>
                      <a
                        aria-label={`Email ${member.firstName} ${member.lastName}`}
                        href={`mailto:${member.email}`}
                      >
                        {member.email}
                      </a>
                    </li>
                  }
                  {member.phone &&
                    <li>
                      <a
                        aria-label={`Call ${member.firstName} ${member.lastName}`}
                        href={`tel:${member.phone}`}
                      >
                        {member.phone}
                      </a>
                    </li>
                  }
                </ul>
              </div>
            </div>
          </div>
        </div>

        <CardGroup
          title="Affiliations"
          cards={createChapterCard(member.chapter)}
        />
      </div>
    );
  }
}
