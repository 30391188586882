import React from 'react';
import { useTitle } from 'hooks/use-title';

export default function Error() {
  useTitle('ET Org Chart - Error');
  return (
    <div className="container bg network-black mb-4">
      <div className="row">
        <div className="col-12">
          <section className="uds-image-background-with-cta">
            <div className="uds-image-background-with-cta-container uds-content-align">
              <span>
                An error occurred during your request.
              </span>
              <a className="btn btn-gold" href="/" role="button">
                Return Home
              </a>
            </div>
            </section>
        </div>
      </div>
    </div>
  );
}
