import { bufferToBase64UrlEncoded, createRandomString, encode, sha256 } from './utils';
import Lock from 'browser-tabs-lock';
import axios from 'axios';
import qs from 'qs';
import { decode, verify } from 'jsonwebtoken';
import pk from './pk';

const lock = new Lock();

export default class ServiceAuthClient {
  constructor(config = {}) {
    this.name = config.name || 'default';
    this.dplBaseUrl = 'https://api.myasuplat-dpl.asu.edu/api';
    this.redirectUrl = config.redirectUrl;
    this.authUrl =
      config.authUrl || 'https://weblogin.asu.edu/serviceauth/oauth2/native/allow';
    this.tokenUrl =
      config.tokenUrl || 'https://weblogin.asu.edu/serviceauth/oauth2/native/token';
    this.clientId = config.clientId || 'default';
    this.loginPath = config.loginPath || '/login';
    this.clientSecret = config.clientSecret || 'serviceauth-public-agent';
    this.scopes = config.scopes || [];

    // session storage keys
    this.codeVerifierKey = `${this.name}.serviceauth.codeVerifier`;
    this.stateKey = `${this.name}.serviceauth.state`;
    this.jwtKey = `${this.name}.jwt.token`;
    this.returnToKey = `${this.name}.app.returnTo`;
  }

  async loginWithRedirect() {
    const url = await this.buildAuthorizeUrl();
    window.location['assign'](url);
  }

  async buildAuthorizeUrl(options) {
    const state = encode(createRandomString());
    const codeVerifier = createRandomString();
    const codeChallengeBuffer = await sha256(codeVerifier);
    const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer);

    localStorage.setItem(this.codeVerifierKey, codeVerifier);
    localStorage.setItem(this.stateKey, state);
    localStorage.setItem(
      this.returnToKey,
      `${window.location.pathname}${window.location.search}`
    );

    var url = this.authUrl;
    url += '?response_type=code';
    url += '&client_id=' + encodeURIComponent(this.clientId);
    url += '&redirect_uri=' + encodeURIComponent(this.redirectUrl);
    url += '&state=' + encodeURIComponent(state);
    url += '&code_challenge_method=S256';
    url += '&code_challenge=' + codeChallenge;
    url += '&scope=' + encodeURIComponent(this.scopes.join(' '));

    console.log(`redirecting to auth server [${url}]`);
    return url;
  }

  async handleRedirectCallback(url = window.location.href) {
    const queryStringFragments = url.split('?').slice(1);

    if (queryStringFragments.length === 0) {
      throw new Error('There are no query params available for parsing.');
    }

    const { state, code } = qs.parse(queryStringFragments.join(''));

    const storedState = localStorage.getItem(this.stateKey);
    const codeVerifier = localStorage.getItem(this.codeVerifierKey);
    let returnTo = localStorage.getItem(this.returnToKey);
    localStorage.removeItem(this.stateKey);
    localStorage.removeItem(this.codeVerifierKey);
    localStorage.removeItem(this.returnToKey);

    //if (state !== storedState) throw new Error('State values do not match.');
    console.log('code: ', code);
    console.log('query state: ', state);
    console.log('session state: ', storedState);
    console.log('code verifier: ', codeVerifier);

    // ASU version
    const response = await axios.post(
      this.tokenUrl,
      qs.stringify({
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: this.redirectUrl,
        client_id: this.clientId,
        // TODO: change back for ASU
        client_secret: this.clientSecret,
        code_verifier: codeVerifier,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    const { data } = response;
    console.log('response from IdP', response);

    // TODO: change back for ASU
    console.log('saving token to session storage');
    console.table(decode(data.access_token));
    localStorage.setItem(this.jwtKey, data.access_token);

    // If user is logging in from login page, return to redirect url
    if (returnTo.startsWith(this.loginPath)) returnTo = '/';

    return { returnTo };
  }

  async checkSession() {
    console.log('checking session');
    if (!localStorage.getItem(this.jwtKey)) {
      console.log('no jwt in session storage');
      return;
    }

    try {
      await this.getTokenSilently();
    } catch (error) {
      console.log('check session error: ', error);
    }
  }

  async getTokenSilently() {
    // 1. Check storage and verify valid
    // 2. Use refresh token
    // 3. loginWithRedirect

    const getTokenFromStorage = (expiryAdjustmentSeconds = 30) => {
      console.log('getting token from storage');
      try {
        const jwt = localStorage.getItem(this.jwtKey);
        if (!jwt) {
          console.log('no token in storage');
          return;
        }

        const decodedToken = verify(jwt, pk, { algorithms: ['RS256'] });

        // check that token isn't expiring too soon
        const nowSeconds = Math.floor(Date.now() / 1000);
        if (decodedToken.exp - expiryAdjustmentSeconds < nowSeconds) {
          console.log(
            `token is expiring in less than ${expiryAdjustmentSeconds} seconds`
          );
          return;
        } else {
          const remainingTimeSeconds =
            decodedToken.exp - nowSeconds - expiryAdjustmentSeconds;
          console.log(`token is ok for another ${remainingTimeSeconds} seconds`);
        }

        return jwt;
      } catch (error) {
        console.log('error getting token from storage: ', error);
        return;
      }
    };

    let token = getTokenFromStorage();
    if (token) return token;

    const retryPromise = async (cb, maxNumberOfRetries = 3) => {
      for (let i = 0; i < maxNumberOfRetries; i++) {
        console.log(`In retryPromise: i=${i}`);
        if (await cb()) {
          return true;
        }
      }

      return false;
    };

    const LOCK_KEY = 'get_token_silently';

    if (await retryPromise(() => lock.acquireLock(LOCK_KEY), 10)) {
      try {
        console.log('checking for tokens again');
        let token = getTokenFromStorage();
        if (token) return token;

        console.log('no valid tokens - redirecting to login');
        this.loginWithRedirect();
      } finally {
        await lock.releaseLock(LOCK_KEY);
      }
    }
  }

  logout() {
    console.log('logging out');
    localStorage.removeItem(this.jwtKey);
    // window.location['assign'](
    //   `${this.loginPath}?severity=success&message=${encodeURIComponent(
    //     'Successfully logged out.'
    //   )}`
    // );
    //window.location['assign'](`https://weblogin.asu.edu/cas/logout`);
  }

  async getUser() {
    console.log('getting user info');
    const decodedToken = decode(localStorage.getItem(this.jwtKey));
    return (decodedToken && decodedToken.email) || (decodedToken && decodedToken.sub);
  }
}
