import { useCallback, useEffect, useReducer, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ServiceAuthClient from './client';
import ServiceAuthContext from './context';
import { initialAuthState } from './state';
import { hasAuthParams } from './utils';

const reducer = (state, action) => {
  console.log('In reducer...');
  console.log('State: ', state);
  console.log('Action: ', action);

  switch (action.type) {
    case 'INITIALISED':
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        error: undefined,
        isLoading: false,
      };
    case 'HANDLE_REDIRECT_COMPLETE':
    case 'GET_ACCESS_TOKEN_COMPLETE':
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case 'ERROR':
    default:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
  }
};

export default function ServiceAuthProvider(props) {
  const {
    children,
    //onRedirectCallback = defaultOnRedirectCallback,
    ...clientOpts
  } = props;
  let history = useHistory();

  const [client] = useState(() => new ServiceAuthClient(clientOpts));
  console.log(client);

  const [state, dispatch] = useReducer(reducer, initialAuthState);
  console.log(state);

  useEffect(() => {
    (async () => {
      console.log('checking for auth params');
      try {
        if (hasAuthParams()) {
          console.log('found auth params');
          const { returnTo } = await client.handleRedirectCallback();
          //onRedirectCallback(returnTo);
          console.log(`returning to ${returnTo}`);
          history.push(returnTo);
        } else {
          await client.checkSession();
        }
        const user = await client.getUser();
        dispatch({ type: 'INITIALISED', user });
      } catch (error) {
        dispatch({ type: 'ERROR', error: error });
      }
    })();
  }, [client]);

  const loginWithRedirect = useCallback((opts) => client.loginWithRedirect(), [client]);
  const logout = useCallback(
    (opts) => {
      // TODO: add support for non-local logout (https://weblogin.asu.edu/cas/logout)
      client.logout();
      history.push(
        `/login?severity=success&message=${encodeURIComponent(
          'Successfully logged out.'
        )}`
      );
      dispatch({ type: 'LOGOUT' });
    },
    [client]
  );

  const getTokenSilently = useCallback(
    async (opts) => {
      console.log('getting token silently');
      let token;
      try {
        token = await client.getTokenSilently();
      } catch (error) {
      } finally {
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client.getUser(),
        });
      }
      return token;
    },
    [client]
  );

  return (
    <ServiceAuthContext.Provider
      value={{ ...state, loginWithRedirect, logout, getTokenSilently }}
    >
      {children}
    </ServiceAuthContext.Provider>
  );
}
