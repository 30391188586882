import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'hooks/use-title';
import { useServiceAuth } from 'auth';
import { getAdminUser, createAdminUser, updateAdminUser } from 'utils/aws-facade';
import Loading from 'components/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function UserForm() {
  useTitle('Edit Administrator User');
  const { getTokenSilently } = useServiceAuth();
  const history = useHistory();
  const { id } = useParams();
  const [asuriteId, setAsuriteId] = useState('');
  const [canManageUsers, setCanManageUsers] = useState(false);
  const [isAsuriteIdError, setAsuRiteIdError] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const isAsuriteIdValid = () => {
    return !!asuriteId;
  };

  const handleAsuriteIdBlur = () => {
    if (isAsuriteIdValid()) {
      setAsuRiteIdError(false);
    } else {
      setAsuRiteIdError(true);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const isFormValid = isAsuriteIdValid();
    setAsuRiteIdError(!isFormValid);
    if (isFormValid) {
      setLoading(true);
      if (id === 'new') {
        await createAdminUser({
          asuriteId,
          canManageUsers,
        }, await getTokenSilently());
      } else {
        await updateAdminUser(id, { canManageUsers }, await getTokenSilently());
      }
      history.push('/admin/users');
    }
  };

  useEffect(() => {
    const fetchData = async (id) => {
      const response = await getAdminUser(id, await getTokenSilently());
      setAsuriteId(response.data.id);
      setCanManageUsers(response.data.canManageUsers);
      setLoading(false);
    };

    if (id !== 'new') {
      fetchData(id);
    } else {
      setLoading(false);
    }

  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <h1>
              <span className="highlight-gold">
                Edit Administrator User
              </span>
            </h1>
            <p className="mt-4 mb-6">
              Add or update details about an administrator user.
            </p>

            <form className="uds-form">
              <div className="form-group">
                <label htmlFor="asurite-id">
                  ASURITE ID
                </label>
                <input
                  type="text"
                  id="asurite-id"
                  className={`form-control ${isAsuriteIdError ? 'is-invalid' : ''}`}
                  placeholder="ASURITE ID..."
                  aria-describedby="asurite-id-error-help"
                  value={asuriteId}
                  onChange={event => setAsuriteId(event.target.value)}
                  onBlur={handleAsuriteIdBlur}
                  disabled={id !== 'new'}
                />
                {isAsuriteIdError &&
                  <small
                    className="form-text invalid-feedback"
                    id="asurite-id-error-help"
                  >
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    Invalid ASURITE ID
                  </small>
                }
              </div>

              <div className="form-check">
                <input
                  type="checkbox"
                  id="can-manage-users"
                  className="form-check-input"
                  checked={canManageUsers}
                  onChange={event => setCanManageUsers(event.target.checked)}
                />
                <label htmlFor="can-manage-users">
                  Can Manage Users
                </label>
              </div>

              <button
                className="btn btn-md btn-maroon mr-1"
                onClick={handleSubmit}
              >
                Save User
              </button>
              <a
                className="btn btn-md btn-maroon"
                href="/admin/users"
              >
                Cancel
              </a>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
