import React from 'react';
import { useLocation } from 'react-router-dom';
import { useServiceAuth } from 'auth';
import { useTitle } from 'hooks/use-title';

export default function Login() {
  useTitle('ET Org Chart - Sign In');
  const query = new URLSearchParams(useLocation().search);
  const message = query.get('message');
  const { loginWithRedirect } = useServiceAuth();

  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="uds-inset-box-container bg network-black">
            <div className="uds-inset-box-content gray-1-bg">
              <h3>
                Sign in using ASU SSO
              </h3>
              <p>
                {message}
              </p>
              <div className="uds-inset-box-buttons">
                <button className="btn btn-gold" onClick={loginWithRedirect}>
                  SIGN IN
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
