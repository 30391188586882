import React, { useEffect, useState } from 'react';
import { useServiceAuth } from 'auth';
import { useTitle } from 'hooks/use-title';
import { getAllContentSections } from 'utils/aws-facade';
import Loading from 'components/Loading';
import Card from 'components/Card';
import CardButton from 'components/CardButton';
import CardGroup from 'components/CardGroup';
import SearchBar from 'components/SearchBar';
import { DEFAULT_BREADCRUMBS } from 'utils/constants';

const createCards = (cardItems, isBodySummarized) => {
  return cardItems
    ?.sort((a, b) => a.orderNum - b.orderNum)
    .map((item, index) => (
      <Card
        title={item.title}
        titlePath={createTitlePath(item)}
        body={item.description}
        buttons={createLeadButtons(item.leads)}
        key={`card-${item.id}-${index}`}
        isBodySummarized={isBodySummarized}
      />
    ));
};

const createLeadButtons = (leads) => {
  return leads?.map((lead, index) => (
    <CardButton
      label={lead.name}
      path={`/members/${lead.emplId}`}
      key={`card-button-${lead.emplId}-${index}`}
    />
  ));
};

const createTitlePath = (item) => {
  if (item.departmentType) {
    if (item.departmentType === 'CORE') {
      return `/cores/${item.departmentId}`;
    } else if (item.departmentType === 'CHAPTER') {
      return `/chapters/${item.departmentId}`;
    }
  }
};

export default function Landing({ setBreadcrumbs }) {
  useTitle('ET Org Chart - Home');
  const { getTokenSilently } = useServiceAuth();
  const [contentSections, setContentSections] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const token = await getTokenSilently();
      const { data } = await getAllContentSections(token);
      setContentSections(data);
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setBreadcrumbs(DEFAULT_BREADCRUMBS);
  });

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <>
        <SearchBar />

        <div className="container mt-7">
          {contentSections
            ?.sort((a, b) => a.orderNum - b.orderNum)
            .map((section) => (
              <CardGroup
                title={section.title}
                description={section.description}
                cards={createCards(section.content, true)}
                key={`content-section-${section.id}`}
              />
            ))}
        </div>
      </>
    );
  }
}
