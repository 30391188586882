// These orgnaziational core constants will eventually be extracted into a data store for easier management but for now they're hard-coded.
export const ORG_CORE_IDS = [
  'U0122',
  'U0133',
  'U0144',
  'U0155',
  'U0166',
];
export const ORG_CORE_DESC = 'A step towards leveraging the collective insight, knowledge, and experience of everyone in ET. These clusters are made up of empowered ET professionals with similar areas of expertise in key fields.';
export const ORG_CORE_TITLE = 'Organizational Cores';

export const CIO_CORE_ID = 'U0110';
export const CIO_CORE_DESC = "Members of today's senior team, those within the management council evolved from being in charge of a hierarchy to focusing on strategy, prioritization, culture, org design, mentorship and innovation.";
export const LEARNING_FUTURES_CHAPTER_ID = 'U0110003';

export const DEFAULT_BREADCRUMBS = [{
  text: 'Home',
  path: '/',
}];
