import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ProtectedRoute from 'auth/ProtectedRoute';
import ServiceAuthProvider from 'auth/provider';
import { DEFAULT_BREADCRUMBS } from 'utils/constants';
import { ASUFooter } from '@asu/component-footer/dist/asuFooter.es';
import Navbar from 'components/Navbar';
import Landing from 'pages/Landing';
import Core from 'pages/Core';
import Chapter from 'pages/Chapter';
import Member from 'pages/Member';
import Search from 'pages/Search';
import Login from 'pages/Login';
import Error from 'pages/Error';
import NotFound from 'pages/NotFound';
import ContentSectionTable from 'pages/admin/ContentSectionTable';
import ContentSectionForm from 'pages/admin/ContentSectionForm';
import ContentForm from 'pages/admin/ContentForm';
import UserTable from 'pages/admin/UserTable';
import UserForm from 'pages/admin/UserForm';
import DepartmentDetailsTable from 'pages/admin/DepartmentDetailsTable';
import DepartmentDetailsForm from 'pages/admin/DepartmentDetailsForm';
import './App.css';

export default function App() {
  const [breadcrumbs, setBreadcrumbs] = useState(DEFAULT_BREADCRUMBS);

  return (
    <Router>
      <ServiceAuthProvider
        name="orgchart"
        clientId={process.env.REACT_APP_CLIENT_ID}
        authUrl="https://weblogin.asu.edu/serviceauth/oauth2/native/allow"
        tokenUrl="https://weblogin.asu.edu/serviceauth/oauth2/native/token"
        redirectUrl={process.env.REACT_APP_REDIRECT_URL}
        scopes={[process.env.REACT_APP_SCOPE]}
      >
        <div className="App">
          <Navbar breadcrumbs={breadcrumbs} />
          <main className="gray-1-bg pb-4" id="skip-to-content" aria-label="Org Chart main content">
            <Switch>
              <ProtectedRoute exact path="/">
                <Landing setBreadcrumbs={setBreadcrumbs} />
              </ProtectedRoute>
              <ProtectedRoute path="/cores/:id">
                <Core setBreadcrumbs={setBreadcrumbs} />
              </ProtectedRoute>
              <ProtectedRoute path="/chapters/:id">
                <Chapter setBreadcrumbs={setBreadcrumbs} />
              </ProtectedRoute>
              <ProtectedRoute path="/members/:id">
                <Member setBreadcrumbs={setBreadcrumbs} />
              </ProtectedRoute>
              <ProtectedRoute path="/search">
                <Search />
              </ProtectedRoute>
              <ProtectedRoute exact path={['/admin', '/admin/content-sections']}>
                <ContentSectionTable />
              </ProtectedRoute>
              <ProtectedRoute exact path='/admin/content-sections/:id'>
                <ContentSectionForm />
              </ProtectedRoute>
              <ProtectedRoute exact path='/admin/content-sections/:contentSectionId/content/:contentId'>
                <ContentForm />
              </ProtectedRoute>
              <ProtectedRoute exact path={'/admin/department-details'}>
                <DepartmentDetailsTable />
              </ProtectedRoute>
              <ProtectedRoute exact path={'/admin/department-details/:id'}>
                <DepartmentDetailsForm />
              </ProtectedRoute>
              <ProtectedRoute exact path={'/admin/users'}>
                <UserTable />
              </ProtectedRoute>
              <ProtectedRoute path='/admin/users/:id'>
                <UserForm />
              </ProtectedRoute>
              <Route path="/login" component={Login} />
              <Route path="/error" component={Error} />
              <Route path="*" component={NotFound} />
            </Switch>
          </main>
          <ASUFooter />
        </div>
      </ServiceAuthProvider>
    </Router>
  );
}
