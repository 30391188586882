import axios from 'axios';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const responseSuccessHandler = (response) => {
  console.log('Successful response.');
  sessionStorage.setItem('orgchart.role', response.headers['asu-et-uioc-role']);
  window.dispatchEvent(new Event('storage'));
  return response;
};

const responseErrorHandler = (error) => {
  console.log('Unsuccessful response: ', error);
  if (error?.response?.status === 401 || error?.response?.status === 403) {
    window.location['assign'](`/login?severity=error&message=${encodeURIComponent('Unauthorized. Please request access.')}`);
  } else {
    window.location.assign('/error');
  }

  return Promise.reject(error);
};

API.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

export default API;
