import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'hooks/use-title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { useServiceAuth } from 'auth';
import { getAllCores, getContent, createContent, updateContent } from 'utils/aws-facade';
import Select from 'react-select';
import LeadForm from 'components/LeadForm';
import Loading from 'components/Loading';

const sortOptionsByLabel = (object1, object2) => {
  const label1 = object1.label.toUpperCase();
  const label2 = object2.label.toUpperCase();
  if (label1 < label2) {
    return -1;
  } else if (label1 > label2) {
    return 1;
  } else {
    return 0;
  }
};

export default function ContentForm() {
  useTitle('Add/Edit Content');
  const { getTokenSilently } = useServiceAuth();
  const { contentSectionId, contentId } = useParams();
  const history = useHistory();
  const [availableCoresAndChapters, setAvailableCoresAndChapters] = useState([]);
  const [title, setTitle] = useState();
  const [isTitleError, setTitleError] = useState(false);
  const [description, setDescription] = useState();
  const [isCoreOrChapter, setCoreOrChapter] = useState(false);
  const [coreOrChapterOption, setCoreOrChapterOption] = useState();
  const [isCoreOrChapterIdError, setCoreOrChapterIdError] = useState(false);
  const [leads, setLeads] = useState([]);
  const [isLoading, setLoading] = useState(true);

  const handleIsCoreOrChapterChange = (event) => {
    setTitleError(false);
    setCoreOrChapterIdError(false);
    setCoreOrChapter(event.target.checked);
    if (!event.target.checked) {
      setCoreOrChapterOption(undefined);
    }
  };

  const isTitleValid = () => {
    if (isCoreOrChapter || title) {
      setTitleError(false);
      return true;
    }
    setTitleError(true);
    return false;
  };

  const isCoreOrChapterSelectionValid = () => {
    if(!isCoreOrChapter || coreOrChapterOption) {
      setCoreOrChapterIdError(false);
      return true;
     }
     setCoreOrChapterIdError(true);
     return false;
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isTitleValid() && isCoreOrChapterSelectionValid()) {
      setLoading(true);
      const requestBody = {
        title,
        description,
        leads: leads?.filter(lead => lead.name || lead.asuriteId),
        departmentId: coreOrChapterOption?.value,
      };

      if (contentId === 'new') {
        await createContent(contentSectionId, requestBody, await getTokenSilently());
      } else {
        await updateContent(contentSectionId, contentId, requestBody, await getTokenSilently());
      }
      history.push(`/admin/content-sections/${contentSectionId}`);
    }
  };

  const selectErrorStyles = {
    container: (provided) => {
      if (isCoreOrChapterIdError) {
        return {
          ...provided,
          borderStyle: 'solid',
          border: '1px solid #b72a2a',
          borderBottom: '8px solid #b72a2a',
        };
      }
      return { ...provided };
    },
  };

  useEffect(() => {
    const prepareCores = (coresAndChaptersData) => {
      return coresAndChaptersData.reduce((coresArray, core) => {
        coresArray.push({
          value: core.id,
          label: `${core.title} (Core)`,
        });
        return coresArray;
      }, []);
    };

    const prepareChapters = (coresAndChaptersData) => {
      return coresAndChaptersData.reduce((chaptersArray, core) => {
        chaptersArray.push(
          ...core.chapters.map(chapter => ({
            value: chapter.id,
            label: `${chapter.title} (Chapter)`,
          }))
        );
        return chaptersArray;
      }, []);
    };

    const prepareSelectOptions = (coresAndChaptersData) => {
      const cores = prepareCores(coresAndChaptersData);
      cores.sort((core1, core2) => sortOptionsByLabel(core1, core2));
      const chapters = prepareChapters(coresAndChaptersData);
      chapters.sort((chapter1, chapter2) => sortOptionsByLabel(chapter1, chapter2));
      return [
        {
          label: 'Cores',
          options: cores,
        },
        {
          label: 'Chapters',
          options: chapters,
        },
      ];
    };

    const fetchContentData = async (contentId) => {
      const token = await getTokenSilently();
      const response = await getContent(contentSectionId, contentId, token);
      if (response.data.departmentId) {
        setCoreOrChapter(true);
        setCoreOrChapterOption({ label: response.data.title, value: response.data.departmentId });
      } else {
        setTitle(response.data.title);
        setDescription(response.data.description);
        setLeads(response.data.leads ? response.data.leads : []);
      }
      setLoading(false);
    };

    const fetchCoreData = async () => {
      const token = await getTokenSilently();
      const response = await getAllCores(token);
      setAvailableCoresAndChapters(prepareSelectOptions(response.data));
    };

    fetchCoreData();

    if (contentId !== 'new') {
      fetchContentData(contentId);
    } else {
      setLoading(false);
    }
  }, []);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <h1>
              <span className="highlight-gold">
                Edit Content
              </span>
            </h1>
            <p className="my-4">
              Create or update content (i.e. cores, chapters, scopes, portfolios, etc.) that appears within a content section.
            </p>

            <form className="uds-form">
              <div className="form-check">
                <input
                  id="is-core-or-chapter"
                  className="form-check-input"
                  type="checkbox"
                  checked={isCoreOrChapter}
                  onChange={handleIsCoreOrChapterChange}
                />
                <label
                  className="form-check-label"
                  htmlFor="is-core-or-chapter"
                >
                  Core or Chapter?
                </label>
              </div>

              {isCoreOrChapter &&
                <div className="form-group core-chapter-select-group">
                  <label htmlFor="core-or-chapter-id">
                    Core/Chapter
                  </label>
                  <Select
                    options={availableCoresAndChapters}
                    value={coreOrChapterOption}
                    onChange={(selectedEntity) => setCoreOrChapterOption(selectedEntity)}
                    styles={selectErrorStyles}
                  />
                  {isCoreOrChapterIdError &&
                    <small
                      className="form-text invalid-feedback"
                      id="core-or-chapter-id-help"
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                      Core or chapter selection is required.
                    </small>
                  }
                  <small>
                    <strong>NOTE:</strong> Because cores and chapters are global and can appear in many different places, management of leads and descriptions for these
                    entities is handled in the <a href="/admin/department-details">Department Details administrative page</a>.
                  </small>
                </div>
              }

              {!isCoreOrChapter &&
                <>
                  <div className="form-group">
                    <label htmlFor="title">
                      Title
                    </label>
                    <input
                      aria-required="true"
                      aria-describedby="title-error-help"
                      id="title"
                      className={`form-control ${isTitleError ? 'is-invalid' : ''}`}
                      placeholder="Title..."
                      value={title}
                      onChange={event => setTitle(event.target.value)}
                      onBlur={isTitleValid}
                    />
                    {isTitleError &&
                      <small
                        className="form-text invalid-feedback"
                        id="title-error-help"
                      >
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                        Title is required
                      </small>
                    }
                  </div>

                  <div className="form-group">
                    <label htmlFor="description">
                      Description
                    </label>
                    <textarea
                      id="description"
                      className="form-control"
                      placeholder="Description..."
                      rows="4"
                      value={description}
                      onChange={event => setDescription(event.target.value)}
                    />
                  </div>

                  <LeadForm
                    leads={leads}
                    setLeads={setLeads}
                  />
                </>
              }

              <div className="row">
                <button
                  className="btn btn-maroon"
                  onClick={handleSubmit}
                >
                  Save
                </button>
                <button
                  className="btn btn-maroon ml-1"
                  onClick={() => history.push(`/admin/content-sections/${contentSectionId}`)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
