import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

export default function CardButton({ label, path }) {
  return (
    <div className="card-button">
      <a href={path} className="btn btn-md btn-maroon" role="button">
        <FontAwesomeIcon icon={faUser} className="pr-1" />
        {label}
      </a>
    </div>
  );
}
