import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTitle } from 'hooks/use-title';
import { useServiceAuth } from 'auth';
import {
  getAllCores,
  getDepartmentDetails,
  createDepartmentDetails,
  updateDepartmentDetails
} from 'utils/aws-facade';
import LeadForm from 'components/LeadForm';
import SkillsForm from 'components/SkillsForm';
import Loading from 'components/Loading';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const sortOptionsByLabel = (object1, object2) => {
  const label1 = object1.label.toUpperCase();
  const label2 = object2.label.toUpperCase();
  if (label1 < label2) {
    return -1;
  } else if (label1 > label2) {
    return 1;
  } else {
    return 0;
  }
};

export default function DepartmentDetailsForm() {
  useTitle('Edit Department Details');
  const { getTokenSilently } = useServiceAuth();
  const history = useHistory();
  const { id } = useParams();
  const [departmentId, setDepartmentId] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [leads, setLeads] = useState([]);
  const [skills, setSkills] = useState([]);
  const [departmentOption, setDepartmentOption] = useState();
  const [isDepartmentOptionError, setDepartmentOptionError] = useState(false);
  const [availableCoresAndChapters, setAvailableCoresAndChapters] = useState([]);
  const [isLoadingOptions, setLoadingOptions] = useState(true);
  const [isLoadingDepartmentDetails, setLoadingDepartmentDetails] = useState(true);

  const handleDepartmentOptionChange = (selectedOption) => {
    setDepartmentOptionError(false);
    setDepartmentOption(selectedOption);
  };

  const isFormValid = () => {
    return isDepartmentOptionValid() && isLeadsValid();
  };

  const isDepartmentOptionValid = () => {
    if (departmentOption) {
      setDepartmentOptionError(false);
      return true;
    }

    setDepartmentOptionError(true);
    return false;
  };

  const isLeadsValid = () => {
    const validatedLeads = leads?.filter(lead => lead.asuriteId || lead.name)
      .map(lead => ({
        asuriteId: lead.asuriteId,
        name: lead.name,
        isError: !lead.asuriteId,
      }));
    setLeads(validatedLeads);

    return !validatedLeads?.find(lead => lead.isError);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (isFormValid()) {
      setLoadingDepartmentDetails(true);
      const requestBody = {
        id: departmentOption?.value,
        title,
        description,
        leads: leads?.filter(lead => lead.name || lead.asuriteId),
        skills,
      };

      if (id === 'new') {
        await createDepartmentDetails(requestBody, await getTokenSilently());
      } else {
        await updateDepartmentDetails(id, requestBody, await getTokenSilently());
      }
      history.push('/admin/department-details');
    }
  };

  const selectErrorStyles = {
    container: (provided) => {
      if (isDepartmentOptionError) {
        return {
          ...provided,
          borderStyle: 'solid',
          border: '1px solid #b72a2a',
          borderBottom: '8px solid #b72a2a',
        };
      }
      return { ...provided };
    },
  };

  useEffect(() => {
    const prepareCores = (coresAndChaptersData) => {
      return coresAndChaptersData.reduce((coresArray, core) => {
        coresArray.push({
          value: core.id,
          label: core.title,
        });
        return coresArray;
      }, []);
    };

    const prepareChapters = (coresAndChaptersData) => {
      return coresAndChaptersData.reduce((chaptersArray, core) => {
        chaptersArray.push(
          ...core.chapters.map(chapter => ({
            value: chapter.id,
            label: chapter.title,
          }))
        );
        return chaptersArray;
      }, []);
    };

    const prepareSelectOptions = (coresAndChaptersData) => {
      const cores = prepareCores(coresAndChaptersData);
      cores.sort((core1, core2) => sortOptionsByLabel(core1, core2));
      const chapters = prepareChapters(coresAndChaptersData);
      chapters.sort((chapter1, chapter2) => sortOptionsByLabel(chapter1, chapter2));
      return [
        {
          label: 'Cores',
          options: cores,
        },
        {
          label: 'Chapters',
          options: chapters,
        },
      ];
    };

    const fetchDepartmentDetailsData = async (id) => {
      const response = await getDepartmentDetails(id, await getTokenSilently());
      setDepartmentId(response.data.id);
      setTitle(response.data.title);
      setDescription(response.data.description);
      setLeads(response.data.leads ? response.data.leads : []);
      setSkills(response.data.skills ? response.data.skills : []);
      setLoadingDepartmentDetails(false);
    };

    const fetchCoreAndChapterData = async () => {
      const token = await getTokenSilently();
      const response = await getAllCores(token);
      setAvailableCoresAndChapters(prepareSelectOptions(response.data));
      setLoadingOptions(false);
    };

    fetchCoreAndChapterData();

    if (id !== 'new') {
      fetchDepartmentDetailsData(id);
    } else {
      setLoadingDepartmentDetails(false);
    }
  }, []);

  useEffect(() => {
    const selectedOption = availableCoresAndChapters
      .flatMap(optionGroup => optionGroup.options)
      .find(option => option.value === departmentId);
    setDepartmentOption(selectedOption);
  }, [departmentId, availableCoresAndChapters]);

  if (isLoadingOptions || isLoadingDepartmentDetails) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row mt-4">
          <div className="col">
            <h1>
              <span className="highlight-gold">
                Edit Department Details
              </span>
            </h1>
            <p className="mt-4 mb-6">
              Add or update details that appear in the ET Org Chart for core and chapter entities.
            </p>

            <form className="uds-form">
              <div className="form-group core-chapter-select-group">
                <label htmlFor="core-or-chapter-id">
                  Core/Chapter
                </label>
                <Select
                  options={availableCoresAndChapters}
                  value={departmentOption}
                  onChange={(selectedEntity) => handleDepartmentOptionChange(selectedEntity)}
                  styles={selectErrorStyles}
                  isDisabled={id !== 'new'}
                />
                {isDepartmentOptionError &&
                  <small
                    className="form-text invalid-feedback"
                    id="core-or-chapter-id-help"
                  >
                    <FontAwesomeIcon icon={faTriangleExclamation} />
                    Core or chapter selection is required.
                  </small>
                }
              </div>

              <div className="form-group">
                <label htmlFor="title">
                  Alternate Title
                </label>
                <input
                  type="text"
                  id="title"
                  className='form-control'
                  placeholder="Alternate Title..."
                  value={title}
                  onChange={event => setTitle(event.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="description">
                  Description
                </label>
                <textarea
                  id="description"
                  className="form-control"
                  placeholder="Description..."
                  rows="4"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              </div>

              <LeadForm
                leads={leads}
                setLeads={setLeads}
              />

              <SkillsForm
                skills={skills}
                setSkills={setSkills}
              />

              <button
                className="btn btn-md btn-maroon mr-1"
                onClick={handleSubmit}
              >
                Save Department Description
              </button>
              <a
                className="btn btn-md btn-maroon"
                href="/admin/department-details"
              >
                Cancel
              </a>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
