import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useServiceAuth } from 'auth';
import { useTitle } from 'hooks/use-title';
import { getChapter } from 'utils/aws-facade';
import Loading from 'components/Loading';
import Card from 'components/Card';
import CardGroup from 'components/CardGroup';
import { DEFAULT_BREADCRUMBS } from 'utils/constants';

const createMemberCards = (members) => {
  return members.map((member, index) =>
    <Card
      title={`${member.firstName} ${member.lastName}`}
      titlePath={`/members/${member.emplId}`}
      body={member.title}
      key={`member-card-${index}`}
      isBodySummarized={true}
    />
  );
};

export default function Chapter({ setBreadcrumbs }) {
  const { getTokenSilently } = useServiceAuth();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [chapter, setChapter] = useState({});
  useTitle(chapter.title ?? 'Loading');

  useEffect(() => {
    async function fetchData() {
      const response = await getChapter(id, await getTokenSilently());
      setChapter({ ...chapter, ...response.data });
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    setBreadcrumbs([
      ...DEFAULT_BREADCRUMBS,
      {
        text: chapter?.core?.title,
        path: `/cores/${chapter?.core?.id}`,
      },
      {
        text: chapter.title,
        path: `/chapters/${chapter.id}`,
      },
    ]);
  }, [chapter, setBreadcrumbs]);

  if (isLoading) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1>{chapter.title}</h1>
          </div>
        </div>
        {(chapter.description || chapter.skills) &&
          <div className="row">
            {chapter.description &&
              <div className={chapter.skills ? 'col-xl-8 col-lg-8 col-md-8 col-12' : 'col-12'}>
                <h2>Description</h2>
                {chapter.description.split('<br />').map((paragraphText, index) => 
                  <p key={`description-p-${index}`}>
                    {paragraphText}
                  </p>
                )}
              </div>
            }
            {chapter.skills &&
              <div className={chapter.description ? 'col-xl-4 col-lg-4 col-md-4 col-12' : 'col-12'}>
                <h2>Skills</h2>
                <ul>
                  {chapter.skills.map((skill, index) =>
                    <li key={`chapter-skill-${index}`}>{skill}</li>
                  )}
                </ul>
              </div>
            }
          </div>
        }
        <div className="row">
          <div className="col-12">
            <CardGroup
              title="Members"
              cards={createMemberCards(chapter.members)}
            />
          </div>
        </div >
      </div >
    );
  }
}
