import React from 'react';

export default function Breadcrumb({ breadcrumb, isCurrent }) {
  if(isCurrent) {
    return (
      <li className="breadcrumb-item active" aria-current="page">
        {breadcrumb.text}
      </li>
    );
  } else {
    return (
      <li className="breadcrumb-item">
        <a href={breadcrumb.path}>{breadcrumb.text}</a>
      </li>
    );
  }
}
