import React from 'react';

export default function Card({ title, titlePath, body, buttons, isBodySummarized = false }) {
  // Preapre the body text by splitting into paragraphs and limiting the length if
  // the summarized property is set to true.
  let bodyParagraphs = [];
  if (body) {
    let bodyText = body;
    if (isBodySummarized && bodyText.length > 200) {
      // Grab the first 200 characters, but make sure we don't cut off the body in
      // the middle of a word.
      bodyText = body.substring(0, 200);
      bodyText = bodyText.substring(0, bodyText.lastIndexOf(' ')) + '...';
    }
    bodyParagraphs = bodyText.split('<br />');
  }

  return (
    <div className="col col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 pb-2">
      <div className="card">
        <div className="card-header">
          <h3>
            {titlePath
              ? <a href={titlePath}>{title}</a>
              : title
            }
          </h3>
        </div>

        {body &&
          <div className="card-body">
            {bodyParagraphs.map((paragraphText, index) =>
              <p
                className="card-text"
                key={`${title}-card-text-${index}`}
              >
                {paragraphText}
              </p>
            )}
          </div>
        }

        {buttons &&
          <div className="card-buttons">
            {buttons}
          </div>
        }
      </div>
    </div>
  );
}
