import { createContext } from 'react';
import { initialAuthState } from 'auth/state';

const stub = () => {
  throw new Error('You forgot to wrap your component in <ServiceAuthProvider>');
};

const initialContext = {
  ...initialAuthState,
  loginWithRedirect: stub,
  getTokenSilently: stub,
  logout: stub,
};

const ServiceAuthContext = createContext(initialContext);

export default ServiceAuthContext;
