import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

export default function LeadForm({ leads, setLeads }) {
  const hanldeLeadUpdate = (event, index) => {
    const leadData = [...leads];
    leadData[index][event.target.name] = event.target.value;
    setLeads(leadData);
  };

  const handleLeadAdd = (event) => {
    event.preventDefault();
    const leadData = [...leads];
    leadData.push({
      name: '',
      asuriteId: '',
      isError: false,
    });
    setLeads(leadData);
  };

  const handleLeadDelete = (event, index) => {
    event.preventDefault();
    const leadData = [...leads];
    leadData.splice(index, 1);
    setLeads(leadData);
  };

  return (
    <div className="uds-table mb-4">
      <table>
        <thead>
          <tr>
            <th>ASURITE ID</th>
            <th>Lead Name</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {leads?.map((lead, index) =>
            <tr key={`lead-row-${index}`}>
              <td>
                <div className="form-group">
                  <input
                    className="form-control"
                    value={lead.asuriteId}
                    onChange={event => hanldeLeadUpdate(event, index)}
                    name="asuriteId"
                    placeholder="ASURITE ID..."
                  />
                  {lead.isError &&
                    <small
                      className="form-text invalid-feedback"
                      id="core-or-chapter-id-help"
                    >
                      <FontAwesomeIcon icon={faTriangleExclamation} />
                      ASURITE ID is required.
                    </small>
                  }
                </div>
              </td>
              <td>
                <div className="form-group">
                  <input
                    className="form-control"
                    value={lead.name}
                    onChange={event => hanldeLeadUpdate(event, index)}
                    name="name"
                    placeholder="Name (Optional)..."
                  />
                  {/* This div only exists to keep page styling consistent when an error message is displayed under the ASURITE ID cell. */}
                  {lead.isError &&
                    <div className="table-error-pad" aria-hidden="true"></div>
                  }
                </div>
              </td>
              <td style={{ paddingTop: '24px', verticalAlign: 'top' }}>
                <button
                  className="btn btn-sm btn-maroon"
                  onClick={event => handleLeadDelete(event, index)}
                >
                  Remove
                </button>
              </td>
            </tr>
          )}
          <tr>
            <td colSpan="3">
              <button
                className="btn btn-md btn-maroon"
                onClick={event => handleLeadAdd(event)}
              >
                Add Lead
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
