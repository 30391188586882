import { useState, useEffect } from 'react';
import { useServiceAuth } from 'auth';
import { ASUHeader } from '@asu/component-header/dist/asuHeader.es';
import desktopLogo from '@asu/component-header/dist/assets/img/arizona-state-university-logo-vertical.png';
import mobileLogo from '@asu/component-header/dist/assets/img/arizona-state-university-logo.png';
import Breadcrumbs from './Breadcrumbs';

export default function Navbar({ breadcrumbs }) {
  const { isAuthenticated, user, logout, loginWithRedirect } = useServiceAuth();
  const [role, setRole] = useState('user');
  const ssoNameCookeValue = document.cookie.split('; ')?.find(cookie => cookie.startsWith('SSONAME'))?.split('=')[1];

  useEffect(() => {
    const handleSessionUpdate = () => setRole(sessionStorage.getItem('orgchart.role'));
    window.addEventListener('storage', handleSessionUpdate);
    return () => window.removeEventListener('storage', handleSessionUpdate);
  }, []);

  let navTree = [];
  if (role === 'admin-content') {
    navTree = [
      {
        href: '/',
        text: 'Home',
        type: 'icon-home',
      },
      {
        text: 'Admin',
        href: '#',
        items: [
          [
            {
              href: '/admin/content-sections',
              text: 'Content Sections',
            },
            {
              href: '/admin/department-details',
              text: 'Department Details',
            },
          ]
        ]
      },
    ];
  } else if (role === 'admin-all') {
    navTree = [
      {
        href: '/',
        text: 'Home',
        type: 'icon-home',
      },
      {
        text: 'Admin',
        href: '#',
        items: [
          [
            {
              href: '/admin/content-sections',
              text: 'Content Sections',
            },
            {
              href: '/admin/department-details',
              text: 'Department Details',
            },
            {
              href: '/admin/users',
              text: 'Administrative Users',
            },
          ]
        ]
      },
    ];
  }

  return (
    <div className="bg-gray-1">
      <ASUHeader
        title="ET Org Chart"
        logo={{
          alt: "ASU Logo",
          src: desktopLogo,
          mobileSrc: mobileLogo,
        }}
        loggedIn={isAuthenticated}
        userName={ssoNameCookeValue ? ssoNameCookeValue : user}
        onLoginClick={loginWithRedirect}
        onLogoutClick={logout}
        searchUrl="https://search.asu.edu/search"
        navTree={navTree}
      />
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </div>
  );
}
